import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import "../styles/modal.css";
import tw from 'twin.macro'
import {
  ButtonStyle,
  CopyStyle,
  SubheadSMStyle,
} from '../components/UI'
import { setUtmParams, areUtmParamsAvailable } from '../utils';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const AppModal = ({ setIsOpen, showThankyouModal, jobDetails }) => {
  const {
    formState: { errors },
  } = useForm()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const topForm = useForm()
  const bottomForm = useForm()

  const dispatch = useDispatch();
  const utmParams = useSelector((state) => state.utmParams);

  useEffect(() => {
    if (!areUtmParamsAvailable(utmParams)) {
      dispatch({ type: "SAVE_UTM_PARAMS", payload: setUtmParams() });
    }
  }, [dispatch])

  const onSubmit = (data) => {
    const params = {
      ...utmParams,
      name: data.name,
      email: data.email,
      location: jobDetails?.location,
      industry: jobDetails?.industry,
      skill: jobDetails?.title
    }

    const optionsZapier = {
      method: 'POST',
      body: JSON.stringify(params),
    }

    fetch(
      'https://hooks.zapier.com/hooks/catch/4090899/bjzmjn0/',
      optionsZapier
    ).then((response) => {
      response.json()
      if (response.status === 200 && !formSubmitted) {
        setFormSubmitted(true)

        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'Applied on Job',
            eventLabel: 'Apply Now',
            field: 'Job',
            jobTitle: jobDetails?.title
          })
        }
      }

    })

    setIsOpen(false)
    showThankyouModal(true)
  }

  return (
    <>
      <div className='darkBG' onClick={() => setIsOpen(false)} />
      <div className='centered'>
        <div className='modal'>
          <div className='modalHeader'>
            <span className='close-modal' onClick={() => setIsOpen(false)}>X</span>
            <h5 className='heading-modal'>Send me the app</h5>
          </div>

          <div className='modal-container'>
            {/* Are you sure you want to delete the item? */}
            <form
              onSubmit={topForm.handleSubmit(onSubmit)}
            // tw="grid grid-cols-1 lg:(grid-cols-[1fr repeat(12, calc(1024px/12)) 1fr] relative)"
            >
              <div css={[tw``]}>
                <div css={[formSubmitted && tw`invisible`]}>
                  {/* Name */}
                  <div tw="mb-10">
                    <label css={[SubheadSMStyle, tw`block mb-3.5 text-black`]} htmlFor="Name">
                      Full Name*
                    </label>
                    <div className="input-group">
                      <input
                        css={[CopyStyle, tw`border border-black w-full px-7 py-7 text-black`]}
                        type="text"
                        id="Name"
                        name="Name"
                        placeholder="Type your name"
                        {...topForm.register('name', { required: true })}
                      />
                      {topForm.formState.errors.name && (
                        <span css={[CopyStyle, tw`text-red-500`]}>
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Email */}
                  <div tw="mb-10">
                    <label css={[SubheadSMStyle, tw`block mb-3.5 text-black`]} htmlFor="Email">
                      Email*
                    </label>
                    <div className="input-group">
                      <input
                        css={[CopyStyle, tw`border border-black w-full px-7 py-7 text-black`]}
                        type="email"
                        id="Email"
                        name="Email"
                        placeholder="Type your email"
                        {...topForm.register('email', {
                          required: true,
                          pattern:
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                      />
                      {topForm.formState.errors.email && (
                        <span css={[CopyStyle, tw`text-red-500`]}>
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Submit */}
                  <div>
                    <input
                      type="submit"
                      value="Submit"
                      css={[
                        ButtonStyle,
                        tw`bg-[#FF4D00] text-white inline-block py-7 px-8 w-full text-center border-[#FF4D00] border-[1.5px] cursor-pointer lg:(px-16 w-auto)`,
                        tw`transition-all transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:(text-[#202C3A] bg-white)`,
                      ]}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div >
    </>
  );
};

export default AppModal;